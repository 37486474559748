/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/cdk/overlay-prebuilt.css';

/* important styles */
:host ::ng-deep .dp-material .dp-picker-input {
    width: 100% !important;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 2rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 2.4;
    position: relative;
}

.cdk-overlay-container {
    z-index: 99999 !important;
}

.modal-backdrop {
  z-index: 90;
}

/* important styles */

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    /* display: none; */
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    /* -ms-overflow-style: none; */
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
}


/* You can add global styles to this file, and also import other style files */
.asterisk:after {
    content: " *";
    color: red;
}

.wrapper {
    margin-top: 30px;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
    background-color: #2f8ee0;
}

.nav-pills>.active>a>.badge {
    color: #2f8ee0;
}

.wrapper-page .card {
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.22)
}


/* ==============
  Card
===================*/
.card {
    border: none;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.22)
}

.card-primary {
    background-color: #2f8ee0;
    border-color: #2f8ee0;
}

.card-success {
    background-color: #6fd088;
    border-color: #6fd088;
}

.card-info {
    background-color: #4bbbce;
    border-color: #4bbbce;
}

.card-warning {
    background-color: #ffbb44;
    border-color: #ffbb44;
}

.card-danger {
    background-color: #f24734;
    border-color: #f24734;
}

.card-header {
    border-bottom: 1px solid rgba(10, 24, 50, 0.05);
}

/* =============
   Tabs & Accordions
============= */
.nav-tabs .nav-link,
.nav-pills .nav-link {
    color: #0a1832;
    font-weight: 700;
}

.nav-tabs-custom {
    border-bottom: 2px solid #DDD;
}

.nav-tabs-custom .nav-item {
    position: relative;
}

.nav-tabs-custom .nav-item .nav-link {
    border: none !important;
    font-weight: 700;
}

.nav-tabs-custom>li>a {
    color: #0a1832;
}

.nav-tabs-custom>li>a::after {
    content: "";
    background: #ed1e63;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.nav-tabs-custom>li>a.active::after,
.nav-tabs-custom>li:hover>a::after {
    transform: scale(1);
}

.nav-tabs-custom>li>a.active {
    color: #ed1e63 !important;
}



.cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 2px solid #59698d;
    border-color: #59698d;
    transition: all 0.2s ease;
}

.cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #59698d;
    border-color: #59698d;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
}

.cbx span:last-child {
    padding-left: 3px;
}

.cbx:hover span:first-child {
    border-color: #06c3fd;
}

.inp-cbx:checked+.cbx span:first-child {
    background: #59698d;
    border-color: #59698d;
    animation: wave 0.4s ease;
}

.inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.inp-cbx:checked+.cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}

.min_h {
    min-height: 200px;
    height: 200px;
}

td {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}


.snackbars {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    z-index: 2050;
    overflow: visible;
}

.snackbar {
    position: fixed;
    box-sizing: border-box;
    left: calc(100% - 344px);
    bottom: 105px;
    width: 344px;
    /* margin-left: -172px; */
    z-index: 20509;
    transform-origin: center;
    will-change: transform;
    transition: transform 300ms ease, opacity 300ms ease;
}

.snackbar[aria-hidden='false'] {
    -webkit-animation: snackbar-show 300ms ease 1;
    animation: snackbar-show 300ms ease 1;
}

.snackbar[aria-hidden='true'] {
    -webkit-animation: snackbar-hide 300ms ease forwards 1;
    animation: snackbar-hide 300ms ease forwards 1;
}

@media (min-width: 1080px) {
    .snackbars-right .snackbar {
        left: auto;
        right: 20px;
        margin-left: 0;
    }

    .snackbars-left .snackbar {
        left: 20px;
        margin-left: 0;
    }
}

@-webkit-keyframes snackbar-show {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes snackbar-show {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
}

@-webkit-keyframes snackbar-hide {
    to {
        opacity: 0;
        transform: translateY(100%);
    }
}

@keyframes snackbar-hide {
    to {
        opacity: 0;
        transform: translateY(100%);
    }
}

@media (max-width: 400px) {
    .snackbar {
        width: 100%;
        bottom: 0;
        left: 0;
        margin-left: 0;
        border-radius: 0;
    }
}

.snackbar--container {
    display: flex;
    background: #2a2a2a;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    color: #eee;
    cursor: default;
    margin-bottom: 10px;
}

.snackbar--text {
    flex: 1 1 auto;
    padding: 16px;
    font-size: 100%;
}

.snackbar--button {
    position: relative;
    flex: 0 1 auto;
    padding: 8px;
    height: 36px;
    margin: auto 8px auto -8px;
    min-width: 7em;
    background: none;
    border: none;
    border-radius: 3px;
    color: lightgreen;
    font-weight: inherit;
    letter-spacing: 0.05em;
    font-size: 100%;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 200ms ease;
    outline: none;
}

.snackbar--button:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.snackbar--button:focus:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120%;
    height: 0;
    padding: 0 0 120%;
    margin: -60% 0 0 -60%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform-origin: center;
    will-change: transform;
    -webkit-animation: focus-ring 300ms ease-out forwards 1;
    animation: focus-ring 300ms ease-out forwards 1;
    pointer-events: none;
}

@-webkit-keyframes focus-ring {
    from {
        transform: scale(0.01);
    }
}

@keyframes focus-ring {
    from {
        transform: scale(0.01);
    }
}